html {
  font-size: 12px;
  text-align: left;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.multistatecountry > div.p-checkbox-box.p-highlight, .multistatecountry>div.p-checkbox-box.p-highlight:hover, .multistatecountry>div.p-checkbox-box.p-highlight:focus, .multistatecountry>div.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: transparent!important;
  background: transparent!important;
  box-shadow: none!important;
}

.legals button.p-dialog-header-icon:focus {
  box-shadow: 0 0 0 0.2rem rgb(160, 200, 88, 0.2)!important;
}

.legals button.p-dialog-header-icon:hover {
  background: rgb(160, 200, 88, 0.2)!important;
}

